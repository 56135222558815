import React, { Component } from 'react'
import OwlCarousel from "react-owl-carousel2"
import Contacto from '../../component/contacto/contacto'
import MediaQuery from 'react-responsive';
import './inicio.scss'
import Icon from '../../component/UI/icon'
import logo from '../../data/play-load.json'
import $ from "jquery"
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery';
import 'lightgallery/dist/js/lightgallery-all';
import RegistroModal from '../../component/contacto/registroModal';
import ContactoModal from '../../component/contacto/contactoModal';
import {Helmet} from "react-helmet";
import dataInicio from '../../data/inicio'

const video = require("../../assets/images/video.mp4")
export default class Inicio extends Component {
    constructor(props){
        super(props)

        this.props = props
        this.TYPE_REGISTER = {
            plano: {
                name:"Descargar planos",
                paragraph:"Completa tus datos para poder descargar el plano.",
                link: "/gracias-planos"
            }
        }
        this.state = {
            value:"",
            active:false,
            hide:false,
            modalShow:false,
            dataRegister: false,
            events: {
                onDragged: this.onDragged,
                onChanged: function (item) {}
            },
            eventsTipologia: {
                onDragged: this.onDraggedTipologia,
                onChanged: function (item) {}
            },
            indexTipologia: 1,
            options: {
                rewind: false,
                loop: false,
                nav: false,
                center: false,
                autoWidth: true,
                speed: 1000,
                smartSpeed: 300,
                dots: true,

            },
            optionsModal: {
                openModal: this.openModal,
                showMessage: this.showMessage,
            },
            modalShow: false,
            showMessage: false,
            type: ""
           
        }
    }
    prevTipologia(){
        this.sliderTipologia.prev()
        let changes = setInterval(() => {
            this.setState({indexTipologia:this.sliderTipologia.currentPosition+1})
            clearInterval(changes)
        }, 500);
    }
    nextTipologia(){
        this.sliderTipologia.next()
        let changes = setInterval(() => {
            this.setState({indexTipologia:this.sliderTipologia.currentPosition+1})
            clearInterval(changes)
        }, 500);
    }
    componentDidMount(){
        $('.video').lightGallery({
            selector: '.logo',
            thumbnail:true,
            animateThumb: false,
            showThumbByDefault: false,
            subHtmlSelectorRelative: true,
            rotate:false,
            actualSize:false,
            download:true,
            pinterest:false,
            googlePlus:false,
            twitter:false,
        });
        $('.content-img-tipologia').lightGallery({
            selector: '.lupa',
            thumbnail:true,
            animateThumb: false,
            showThumbByDefault: false,
            subHtmlSelectorRelative: true,
            rotate:false,
            actualSize:false,
            download:true,
            pinterest:false,
            googlePlus:false,
            twitter:false,
        });
        $('.content-gallery').lightGallery({
            selector: '.expand-img',
            thumbnail:true,
            animateThumb: false,
            showThumbByDefault: false,
            subHtmlSelectorRelative: true,
            rotate:false,
            actualSize:false,
            download:true,
            pinterest:false,
            googlePlus:false,
            twitter:false,
        });
    }
    hideLoading(){
      //  this.setState({hide:true})
    }
    transformBase = (value,classes,reg,space) => { 
        let reduce = "";
        let arrayWords = value.split(reg)
        let spaceLetter = ''
        console.log('space',space)
        if(space){
            spaceLetter = ''
        }
        let createSpan = arrayWords.map((e,index)=>{
            reduce += `<span class='${classes} y-hidden'><p class='animate animate-${index}'>${e}</p></span>${spaceLetter}`
        })
        return reduce
    }
    openRegistro = (type) => {
        this.setState({modalShow:true,dataRegister:this.TYPE_REGISTER[type]})
    }
    closeModal(){
        this.setState({modalShow: false})
    }
    openModal(type){
        this.setState({modalShow: true, type: type})
    }
    showMessage(){
        this.setState({showMessage:!this.state.showMessage})
    }
    prev(){
        this.sliderComunes.prev()
    }
    next(){
        this.sliderComunes.next()
    }
    onDragged = (e)=>{
        console.log(e)
    }
    onDraggedTipologia = (item) => {
        console.log(item)
        let count = setInterval(() => {
            this.setState({indexTipologia:item.page.index+1})  
            clearInterval(count)
        }, 200);
    }

    moveTo(elementFrom,elementTo){
        try {
            let navHeight = document.querySelector("#nav").clientHeight
            if(document.querySelector(elementTo)){
                window.scrollTo(this.offset(elementFrom).top,this.offset(elementTo).top-(navHeight-50))
            }
        } catch (error) {
            
        }
    }
    offset(e){
        let element = document.querySelector(e)
        if(element){
            try { 
                let top = element.getBoundingClientRect().top + window.scrollY 
                let left = element.getBoundingClientRect().left
                return {top:top,left: left}
            }catch(err){
                return "dosnt exist"
            }
        }else{
            console.log("doesn't exist")
        }
    }
    render() {
        return (
            <div className="template inicio">
                <Helmet>
                    <title>Ibher Perú | Departamentos en venta en Miraflores</title>
                    <meta name="description" content="¡Entrega Inmediata! Departamentos de 1 y 2 dormitorios en venta en Av. Roca y Boloña 290, Miraflores. Adquiere tu depa al mejor precio del distrito desde $161,000* ¡Cotiza aquí!"></meta>
                    <meta name="keywords" content={`ibher perú, magnolia ibher perú, magnolia miraflores, miraflores, departamentos, departamentos en venta, venta de departamentos, departamentos en miraflores, departamentos en venta en miraflores, inmobiliaria, inmobiliaria en lima, proyecto inmobiliario`}></meta>
                </Helmet>
                <div className={`modal ${this.state.modalShow ? "active" : ""}`}>
                    <div className="content-modal">
                        <div className="close" onClick={()=>{this.closeModal()}}>
                            <div className="icon btn-close"></div>
                        </div>                    
                        <ContactoModal 
                            optionsModal={this.state.optionsModal}
                            type={this.state.type} 
                            showMessage={this.state.showMessage}
                            ></ContactoModal>
                    </div>
                </div>
                <section className="section-title">
                    <MediaQuery query="(min-width: 800px)">
                        <div className="container">
                            <div className="content-text">
                                <div className="content-title">
                                    <div className="form-inicio">
                                    </div>
                                    <picture className="expand-img">
                                        <source media="(max-width: 700px)" srcSet={this.props.data.titleMovil}></source>
                                        <h1 className='paragraph'>
                                            <span className='t-1 c-transparent'>
                                                DEPARTAMENTOS <br/>ENTREGA INMEDIATA
                                            </span>
                                            <br/>
                                            <span className='t-2 c-white'>
                                                DESDE 80M²
                                            </span>
                                        </h1>
                                    </picture>
                                    <p className="paragraph bold" dangerouslySetInnerHTML={{__html: this.props.data.paragraph}}>
                                    </p>
                                </div>
                                <HtmlPrice 
                                    data={this.props.data}
                                    moveTo={this.moveTo}
                                    offset={this.offset}
                                    anchorLinkGoto={this.anchorLinkGoto}
                                ></HtmlPrice>
                            </div>
                            <div className="content-img video" >
                                <div style={{display:'none'}} id="video1">
                                    <video class="lg-video-object lg-html5" controls preload="none">
                                        <source src={video} type="video/mp4" />
                                    </video>
                                </div>
                                <div className="logo " data-html="#video1" onMouseOver={this.hideLoading}>
                                    <Icon icon={logo} ref={element => {this.logo = element;}} event={this.hideLoading}></Icon>
                                    <div className="title-content-img">
                                        Ver video
                                    </div>
                                </div>
                                <img src={this.props.data.imgTemplate} alt="Video Conceptual Edificio Magnolia Departamentos en Miraflores"></img>
                            </div>
                        </div>
                    </MediaQuery>
                    <MediaQuery query="(max-width: 800px)">
                        <div className="container">
                            <div className="content-text">
                                <div className="content-title">
                                    <p className="paragraph bold" dangerouslySetInnerHTML={{__html: this.props.data.paragraph}}>                                       
                                    </p>
                                    <picture className="expand-img">
                                        <source media="(max-width: 800px)" srcSet={this.props.data.titleMovil}></source>
                                        <p className='paragraph'>
                                            <span className='t-1 c-transparent'>
                                                DEPARTAMENTOS <br/>ENTREGA INMEDIATA
                                            </span>
                                            <br/>
                                            <span className='t-2 c-white'>
                                                DESDE 80M²
                                            </span>
                                        </p>
                                    </picture>
                                </div>                               
                            </div>                           
                            <div className="content-img video">
                                <div style={{display:'none'}} id="video2">
                                    <video class="lg-video-object lg-html5" controls preload="none">
                                        <source src={video} type="video/mp4" />
                                    </video>
                                </div>
                                <div className="logo " data-html="#video2" onMouseOver={this.hideLoading}>
                                    <Icon icon={logo} ref={element => {this.logo = element;}} event={this.hideLoading}></Icon>
                                    <div className="title-content-img">
                                        Ver video
                                    </div>
                                </div>
                                <img src={this.props.data.imgTemplate} alt='Video Conceptual Edificio Magnolia Departamentos en Miraflores'></img>
                            </div>
                            <HtmlPrice data={this.props.data}></HtmlPrice>
                        </div>
                    </MediaQuery>
                </section>
                <section className="section-gallery" id="galeria">
                    <div className="background-form"></div>
                    <div className="container">
                        <h2 className="title-gallery">GALER&Iacute;A</h2>
                        <div className="content-gallery">
                            <div className="btn-nav nav-left" onClick={()=>{this.prev()}}><div className="mask icon-left"></div></div>
                            <div className="btn-nav nav-right" onClick={()=>{this.next()}}><div className="mask icon-right"></div></div>
                            <OwlCarousel ref={sliderComunes => (this.sliderComunes = sliderComunes)}  options={this.state.options} events={this.state.events} className="owl-slider">
                                {
                                    this.props.data.interiores.map((item)=>{
                                        return(
                                            <div className="slider" data-src={item.img} href={item.img}>                                         
                                                <picture className="expand-img" href={item.img}>
                                                    <source media="(min-width: 650px)" srcSet={item.img}></source>
                                                    <img src={item.img} alt={"Magnolia "+item.name+" - Departamentos en Miraflores"}></img>
                                                </picture>
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                        
                    </div>
                </section>
                <section className="section-tipologia" id="tipologias">
                    <img className="background-tipologia" src={require("../../assets/images/icons/background-section-tipologia.png")}></img>
                    <div className="container">
                        <h2 className="title-info">TIPOLOGÍA</h2>
                        <div className="content-tipologias">
                            <div className="btn-nav nav-left" onClick={()=>{this.prevTipologia()}}><div className="mask icon-left"></div></div>
                            <div className="btn-nav nav-right" onClick={()=>{this.nextTipologia()}}><div className="mask icon-right"></div></div>
                            <OwlCarousel ref={sliderTipologia => (this.sliderTipologia = sliderTipologia)}  options={this.state.options} events={this.state.eventsTipologia} className="owl-slider">

                            {

                                this.props.data.tipologias.map((item,index)=>{
                                    return(
                                    <div className="content-slider-tipologia">
                                        <div className="info">
                                            <h2 className="title">DEPARTAMENTO {item.title}</h2>
                                            <div className="line-info">
                                                <span className="sub-title">Área techada</span>
                                                <span className="sub-title c-white area">{item.techado}</span>
                                            </div>
                                            {
                                                item.descubierto ?   
                                                    <div className="line-info">
                                                        <span className="sub-title">Área descubierta</span>
                                                        <span className="sub-title c-white area">{item.descubierto}</span>
                                                    </div>
                                                : null
                                            }
                                            <div className="line-info">
                                                <span className="sub-title">Área total</span>
                                                <span className="sub-title c-white area">{item.total}</span>
                                            </div>
                                            <div className="line-info">
                                                <span className="sub-title">Número de ambientes</span>
                                                <span className="sub-title c-white area">{item.numeroAmbientes}</span>
                                            </div>
                                            <button className="btn-cotizar" onClick={(e)=>{this.openModal(item)}}>Cotizar</button>
                                        </div>
                                        <div className="content-img-tipologia">
                                            <img src={item.img} alt={'Magnolia-Plano '+item.title+" Departamentos en "+this.props.data.district}></img>
                                            <div className="lupa" href={item.img}>
                                                <img src={require("../../assets/images/icons/icon-lupa.svg").default} alt="Magnolia-Ver plano-Departamentos en Miraflores"></img>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                        </div>
                        <div className="contador"><span>{this.state.indexTipologia}</span> / <span>{this.props.data.tipologias.length}</span></div>
                    </div>
                </section>
                <section className="section-map" id="ubicacion">                
                    <div className="content-map">
                    <h2 className="title-info">UBICACIÓN</h2>
                        <picture className="expand-img">
                            <source media="(max-width: 800px)" srcSet={require("../../assets/images/map/mapa-movil.png")}></source>
                            <img src={require("../../assets/images/map/mapa.png")} alt="Mapa de Ubicación Edificio Magnolia en Miraflores"></img>
                        </picture>
                    </div>
                    <div className="content-bottom-title">
                        <p className="paragraph">Av. Roca y Boloña N°290, Miraﬂores</p>
                    </div>                    
                </section>
                <section className="section-formulario" id="formulario-cotizar">
                    <div className="container">
                        <div className="info-form">
                            <img className="brand-info" alt='Ibher Perú' src={require("../../assets/images/icons/brand-info.svg").default}></img>
                            <span className="title">Llena el formulario y un asesor se comunicará contigo.</span>
                            <div className="content-row content-row-1">
                                <div className="icon"><img src={require("../../assets/images/icons/place.svg").default} alt="Ubicación Edificio Magnolia en Miraflores"></img></div>
                                <div className="list">
                                    <span className="item item-1">Av. Roca y Boloña N°290, Miraﬂores</span>                                    
                                </div>
                            </div>
                            <div className="content-row">
                                <div className="icon"><img alt='Contacto Edificio Magnolia en Miraflores' src={require("../../assets/images/icons/phone.svg").default}></img></div>
                                <div className="list">
                                    <a href="tel:+51 998 252 260" className="item">998 252 260</a>
                                </div>
                            </div>
                        </div>
                        <div className="content-formulario">
                           <img className="background-right" src={require("../../assets/images/icons/background-inicio-right.png")} alt=''></img>
                            <div className="background-content-formulario"></div>
                            <Contacto></Contacto>
                        </div>
                    </div>                    
                </section>            
            </div>
        )
    }
}

function HtmlPrice(props){
    
    return(
        <div className="html-price">
            <div className="content-price">

                <h2 className="title-price">
                    <p className='bold' style={{fontFamily:"neo-sans-std-bold"}}>
                        El mejor precio 
                    </p>
                    de Miraflores desde
                    <div className="line-price">
                        <span className="price"> $161,000* </span>
                    </div>
                </h2>
            </div>
            <div className="content-button-price">
                <div href="#tipologias" className="btn-price planos"  onClick={(e)=>{
                    props.moveTo("#nav","#tipologias");
                    }}>Planos</div>
                <a href={dataInicio.brochure}
                  download="Magnolia-Ibher-Brochure-Digital"
                  target="_blank"
                  rel="noopener noreferrer"
                 className="btn-price brochure">Brochure</a>
            </div>
        </div>
    )
}
