import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Footer from './component/footer/footer'
import departamentos from './data/departamentos'
import dataInicio from './data/inicio'
import Navbar from './component/navbar/navbar'
import Inicio from './pages/inicio/inicio';
import { Helmet } from 'react-helmet'
// import Page from './component/range/page'

const Template =  React.lazy(() => import(/* webpackChunkName:"template"*/'./pages/template/template'));
const Thanks =  React.lazy(() => import(/* webpackChunkName:"thanks"*/'./component/thanks/thanks'))
const Flotante =  React.lazy(() => import(/* webpackChunkName:"flotante"*/'./component/flotante/flotante'))

export default class Routes extends Component {
    componentDidMount() {
        function esDispositivoiOS(){
            var userAgent = window.navigator.userAgent;
            console.log(userAgent)
            let typeDevice = (/iPhone|iPod|iPad|Mac/.test(userAgent));
            if(typeDevice){
                document.querySelector("#root").classList.add("IOS")
            }
        }
        esDispositivoiOS()
    }
  render() {
    return (
        <Router>
                <Navbar></Navbar>
            <Suspense fallback={<div></div>}>
            </Suspense>
            <Suspense fallback={<div></div>}>
                <Flotante></Flotante>
            </Suspense>
            <Switch>
                <Route exact path="/">
                    <Inicio data={dataInicio}></Inicio>
                </Route>
                <Route exact path="/DEP-SEMI-SOTANO">
                    <Suspense fallback={<div></div>}>
                        <Template data={departamentos.semiSotano}></Template>
                    </Suspense>
                </Route>
                <Route exact path="/PLANTA-TIPICA">
                    <Suspense fallback={<div></div>}>
                        <Template data={departamentos.plantaTipica}></Template>
                    </Suspense>
                </Route>
                <Route exact path="/DEP-PENT-HOUSE">
                    <Suspense fallback={<div></div>}>
                        <Template data={departamentos.departamentoHouse}></Template>
                    </Suspense>
                </Route>
                <Route exact path="/gracias-registro">
                    <Suspense fallback={<div></div>}>
                        <Helmet>
                            <title>Ibher Perú | Página de gracias de formulario</title>
                            <meta name="description" content='¡Entrega Inmediata! Departamentos de 1 y 2 dormitorios en venta en Av. Roca y Boloña 290, Miraflores. Adquiere tu depa al mejor precio del distrito desde $156,115 ¡Cotiza aquí!'></meta>
                            <meta name="keywords" content='ibher perú, magnolia ibher perú, magnolia miraflores, miraflores, departamentos, departamentos en venta, venta de departamentos, departamentos en miraflores, departamentos en venta en miraflores, inmobiliaria, inmobiliaria en lima, proyecto inmobiliario'></meta>
                        </Helmet>
                        <Thanks></Thanks>
                    </Suspense>
                </Route>
                <Route exact path="/gracias-planos">
                    <Suspense fallback={<div>Cargando...</div>}>
                        <Helmet>
                            <title>Ibher Perú | Página de gracias de planos</title>
                            <meta name="description" content='¡Entrega Inmediata! Departamentos de 1 y 2 dormitorios en venta en Av. Roca y Boloña 290, Miraflores. Adquiere tu depa al mejor precio del distrito desde $156,115 ¡Cotiza aquí!'></meta>
                            <meta name="keywords" content='ibher perú, magnolia ibher perú, magnolia miraflores, miraflores, departamentos, departamentos en venta, venta de departamentos, departamentos en miraflores, departamentos en venta en miraflores, inmobiliaria, inmobiliaria en lima, proyecto inmobiliario'></meta>
                        </Helmet>
                        <Thanks></Thanks>
                    </Suspense>
                </Route>
                <Route exact path="/gracias-brochure">
                    <Suspense fallback={<div>Cargando...</div>}>
                        <Helmet>
                            <title>Ibher Perú | Página de gracias de brochure</title>
                            <meta name="description" content='¡Entrega Inmediata! Departamentos de 1 y 2 dormitorios en venta en Av. Roca y Boloña 290, Miraflores. Adquiere tu depa al mejor precio del distrito desde $156,115 ¡Cotiza aquí!'></meta>
                            <meta name="keywords" content='ibher perú, magnolia ibher perú, magnolia miraflores, miraflores, departamentos, departamentos en venta, venta de departamentos, departamentos en miraflores, departamentos en venta en miraflores, inmobiliaria, inmobiliaria en lima, proyecto inmobiliario'></meta>
                        </Helmet>
                        <Thanks></Thanks>
                    </Suspense>
                </Route>

            </Switch>
            <Footer></Footer>
        </Router>
    )
  }
}

