const departamentos = {
    semiSotano:{
        title:require("../assets/images/template/title-sotano.png"),
        altTitle:"Departamentos Semisotano en Miraflores",
        titleMovil:require("../assets/images/template/title-movil/title-a.png"),
        imgTemplate:require("../assets/images/template/img-interior-a.webp"),
        altimgTemplate:"Magnolia Departamentos Semisotano en Miraflores",
        paragraph:"Departamentos amplios que incluyen acogedoras terrazas y jardines interiores para que disfrutes cada momento al máximo.",
        district:"Miraflores",
        h1:"Departamentos en Miraflores de 2 y 3 dormitorios desde 189 m²",
        metas:{
            title:"Ibher Perú | Departamentos Semisótano en Miraflores",
            description:"¡Entrega Inmediata! Último flat de 2 dormitorios en Av. Roca y Boloña 290, Miraflores. Amplio departamento de 189 m² con jardín natural. Cotiza aquí",
            keywords:"ibher perú, magnolia ibher perú, magnolia miraflores, inmobiliaria, inmobiliaria en lima, proyecto inmobiliario, miraflores, departamentos, departamentos en venta, venta de departamentos, departamentos en miraflores, departamentos en venta en miraflores, flats en venta, flats, departamentos flats",
        },
        tipologias:[
            // {title:"SS01B",techado:"135.30 m²",descubierto:"84.80 m²",total:"220.10 m²",img:require("../assets/images/tipologias/SS01B.webp")},
            //{title:"SS02D",techado:"127.90 m²",descubierto:"61.60 m²",total:"189.50 m²",img:require("../assets/images/tipologias/SS02D.webp")},
        ],
        interiores:[
            {name:"Sala",img:require("../assets/images/template/interiores/all/sala1.webp")},
            {name:"Cocina",img:require("../assets/images/template/interiores/all/cocina1.webp")},
            {name:"Comedor",img:require("../assets/images/template/interiores/all/comedor1.webp")},
            {name:"Dormitorio",img:require("../assets/images/template/interiores/all/dormitorio-principal.webp")},
            {name:"Sala y comedor",img:require("../assets/images/template/interiores/all/sala-comedor.webp")},
            {name:"Baño",img:require("../assets/images/template/interiores/all/bath.webp")},
        ],
    },
    plantaTipica:{
        title:require("../assets/images/template/title-planta-tipica.png"),
        altTitle:"Departamentos Planta Tipica en Miraflores",
        titleMovil:require("../assets/images/template/title-movil/title-b.png"),
        imgTemplate:require("../assets/images/template/img-interior-b.webp"),
        altimgTemplate:"Magnolia Departamentos Flats y Duplex en Miraflores",
        paragraph:"Flats y dúplex ubicados desde el primer al sexto piso del edificio, diseñados con los mejores materiales. Los departamentos cuentan con una inmejorable distribución.",
        district:"Miraflores",
        h1:"Departamentos en Miraflores de 1 y 2 dormitorios desde 80 m²",
        metas:{
            title:"Ibher Perú | Departamentos en Miraflores desde 80 m²",
            description:"¡Entrega inmediata! Departamentos en venta de 1 y 2 dormitorios en Av. Roca y Boloña 290, Miraflores. Conoce nuestros flats y dúplex desde 80 m². Cotiza aquí",
            keywords:"ibher perú, magnolia ibher perú, magnolia miraflores, inmobiliaria, miraflores, departamentos, departamentos en venta, venta de departamentos, departamentos en miraflores, departamentos en venta en miraflores, duplex en venta, duplex, flats en venta, flats, departamentos duplex, departamentos flats, inmobiliaria en lima"
        },
        tipologias:[
            
        ],
        interiores:[
            {name:"Dormitorio",img:require("../assets/images/template/interiores/all/dormitorio-principal.webp")},
            {name:"Sala",img:require("../assets/images/template/interiores/all/sala1.webp")},
            {name:"Cocina",img:require("../assets/images/template/interiores/all/cocina1.webp")},
            {name:"Comedor",img:require("../assets/images/template/interiores/all/comedor1.webp")},
            {name:"Sala y comedor",img:require("../assets/images/template/interiores/all/sala-comedor.webp")},
            {name:"Baño",img:require("../assets/images/template/interiores/all/bath.webp")},
        ],
    },
    departamentoHouse:{
        title:require("../assets/images/template/title-penhouse.png"),
        titleMovil:require("../assets/images/template/title-movil/title-c.png"),
        altTitle:"Departamentos Penthouse en Miraflores",
        imgTemplate:require("../assets/images/template/img-interior-c.webp"),
        altimgTemplate:"Magnolia Departamentos Penthouse en Miraflores",
        paragraph:"Departamentos ubicados en el último piso, que incluyen una amplia terraza con vista panorámica de la ciudad, y con ambientes diseñados para una mayor comodidad.",
        district:"Miraflores",
        h1:"Departamentos en Miraflores de 1 y 2 dormitorios desde 104 m2",
        metas:{
            title:"Ibher Perú | Departamentos Penthouse en Miraflores",
            description:"¡Entrega inmediata! Departamentos en venta de 1 y 2 dormitorios en Av. Roca y Boloña 290, Miraflores. Últimos dos departamentos dúplex desde 125 m². Cotiza aquí",
            keywords:"ibher perú, magnolia ibher perú, magnolia miraflores, inmobiliaria, miraflores, departamentos, departamentos en venta, venta de departamentos, departamentos en miraflores, departamentos en venta en miraflores, duplex en venta, duplex, departamentos duplex, penthouse, inmobiliaria en lima"
        },
        tipologia:{
            title:"DEPARTAMENTO 702 D",
            techado:"127.30 m²",
            descubierto:"42.10 m²",
            img:require("../assets/images/template/tipologia-c.png"),
        },
        tipologias:[
            // {title:"702C",techado:"80.00 m²",descubierto:"24.00 m²",total:"104.00 m²",img:require("../assets/images/tipologias/702C.webp")},
         //   {title:"702D",techado:"127.30 m²",descubierto:"42.10 m²",total:"169.40 m²",img:require("../assets/images/tipologias/702D.webp")},
            // {title:"703A",techado:"123.50 m²",descubierto:"40.50 m²",total:"164.00 m²",img:require("../assets/images/tipologias/703A.webp")},
           // {title:"703D",techado:"94.20 m²",descubierto:"31.30 m²",total:"125.50 m²",img:require("../assets/images/tipologias/703D.webp")},
        ],
        interiores:[
            {name:"Comedor",img:require("../assets/images/template/interiores/pent-house/COMEDOR-201C.webp")},
            {name:"Comedor",img:require("../assets/images/template/interiores/pent-house/COMEDOR-301A.webp")},
            {name:"Comedor",img:require("../assets/images/template/interiores/pent-house/COMEDOR-301B.webp")},
            {name:"Sala y comedor",img:require("../assets/images/template/interiores/pent-house/COMEDORSALA-301A.webp")},
            {name:"Sala y comedor",img:require("../assets/images/template/interiores/pent-house/COMEDOR-SALA301B.webp")},
            {name:"Sala",img:require("../assets/images/template/interiores/pent-house/SALA-201C.webp")},
            {name:"Sala",img:require("../assets/images/template/interiores/pent-house/SALA-301A.webp")},
            {name:"Sala",img:require("../assets/images/template/interiores/pent-house/SALA-301B.webp")},

        ],
    }
}

export default departamentos;