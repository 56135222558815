export const InterestedEmail = ({fname, lname, email, telephone, rooms_amount, contact_time, lead_source}) => `
<table cellspacing="0" cellpadding="0" border="0" style="color:#333;background:#fff;padding:0;margin:0;width:100%;font:15px/1.25em 'Helvetica Neue',Arial,Helvetica">
    <tbody>
    <tr width="100%">
        <td valign="top" align="left" style="background:#eef0f1;font:15px/1.25em 'Helvetica Neue',Arial,Helvetica">
            <table style="border:none;padding:0 18px;margin:50px auto;width:500px">
                <tbody>
                <tr width="100%" height="60">
                    <td valign="top" align="left" style="border-top-left-radius:4px;border-top-right-radius:4px;background:#fff bottom left repeat-x;padding:18px;text-align:center;display:flex;justify-content:space-between;">
                        <img style="object-fit:contain; width: 30%;" src="https://resources.formulaperu.com/uploads/11g9xwls6qn8n3a.png" title="Fórmula">
                        <img style="object-fit:contain; width: 30%;" src="https://resources.formulaperu.com/uploads/bpsxl433xiol987.png" title="Cliente">
                    </td>
                </tr>
                <tr width="100%">
                    <td valign="top" align="left" style="background:#fff;padding:18px">
                        <h1 style="font-size:20px;margin:16px 0;color:#333;text-align:center">${fname} quiere contactarse</h1>
                        <p style="font:15px/1.25em 'Helvetica Neue',Arial,Helvetica;color:#333;text-align:center">La siguiente información proviene de ${lead_source}</p>
                        <div style="background:#f6f7f8;border-radius:3px">
                            <p style="padding-top: 30px;text-align:center">
                                <a href="#" style="color:#606060;font:16px/1.25em 'Helvetica Neue',Arial,Helvetica;text-decoration:none;font-weight:bold" target="_blank">Nombre Completo: ${lname}</a>
                            </p>
                            <p style="padding-top: 10px;text-align:center">
                                <a href="mailto:${email}" style="color:#606060;font:16px/1.25em 'Helvetica Neue',Arial,Helvetica;text-decoration:none;font-weight:bold" target="_blank">Correo Electrónico: ${email}</a>
                            </p>
                            <p style="padding-top: 10px;text-align:center">
                                <a href="tel:${telephone}" style="color:#606060;font:16px/1.25em 'Helvetica Neue',Arial,Helvetica;text-decoration:none;font-weight:bold" target="_blank">Teléfono: ${telephone}</a>
                            </p>                        
                        </div>
                    </td>
                </tr>
                </tbody>
                <td valign="top" align="left" style="border-top-left-radius:4px;border-top-right-radius:4px;background:#fff bottom left repeat-x;padding:10px 18px;text-align:center">
                    Desarrollado por <a href="https://formulaperu.com">Fórmula - Agencia de Marketing</a>
                </td>
            </table>
        </td>
    </tr>
    </tbody>
</table>
`