import React, { Component , createRef, useState } from 'react'
import { Link } from "react-router-dom";
import './navbar.scss'
import MediaQuery from 'react-responsive';

export default class Navbar extends Component {
    constructor(props){
        super(props)
        this.activePosition = this.activePosition.bind(this)
        this.close = this.close.bind(this)
        this.toggle = this.toggle.bind(this)
        this.toggleLink = this.toggleLink.bind(this)
        this.offset = this.offset.bind(this)
        this.moveTo =  this.moveTo.bind(this)
        this.state = {
            expanded:"panel5",
            activeLine:1,
            activeNav:false,
            closeMore:false,
            toggle:false,
        }
        this.nav = React.createRef()
    }
    componentDidMount(){
        if(window.location.pathname == "/"){
            this.setState({activeLine:1})
        }
        if(window.location.pathname == "/DEP-SEMI-SOTANO"){
            this.setState({activeLine:2})
        }
        if(window.location.pathname == "/PLANTA-TIPICA"){
            this.setState({activeLine:3})
        }
        if(window.location.pathname.includes("/DEP-PENT-HOUSE")){
            this.setState({activeLine:4})
        }
    }
    activePosition(e,position){
        window.scrollTo(0,0)
        this.setState({activeLine:position})
        this.toggleLink()
        if(window.location.search.includes("proyectos")){
            this.moveTo("#inicio-page","#all-proyectos")
        }   
    }
    close(e){
        this.setState({closeMore:true})
    }
    toggleLink(e){
        this.setState({toggle:!this.state.toggle})
    }
    toggle(e){
        this.setState({toggle:!this.state.toggle})
    }
    moveTo(elementFrom,elementTo){
        try {
            let navHeight = document.querySelector("#nav").clientHeight
            if(document.querySelector(elementTo)){
                window.scrollTo(this.offset(elementFrom).top,this.offset(elementTo).top-(navHeight-50))
            }
        } catch (error) {
            
        }
    }
    offset(e){
        let element = document.querySelector(e)
        if(element){
            try { 
                let top = element.getBoundingClientRect().top + window.scrollY 
                let left = element.getBoundingClientRect().left
                return {top:top,left: left}
            }catch(err){
                return "dosnt exist"
            }
        }else{
            console.log("doesn't exist")
        }
    }
    
    render() {
        return (
            <nav ref={this.nav} id="nav" className={`navbar ${this.state.activeNav ? "active" : ""}`}>
                <div className="container content-nav">
                    <Link to="/"className="brand">
                        <img className="logo-white" alt='Edificio Magnolia Departamentos en Miraflores' src={require("../../assets/images/icons/brand-magnolia.svg").default}></img>
                    </Link>
                    <MediaQuery query="(min-width: 1200px)">
                        <OptionNav options={this.state} position={this.activePosition} moveTo={this.moveTo}></OptionNav>
                    </MediaQuery>
                    <a target="_blank" href="https://asei.com.pe/" className={`link link-asei`} rel='noreferrer'>
                        <img className='' src={require('../../assets/images/icons/asei.png')} alt="Ibher Perú Miembro ASEI"></img>
                    </a>
                    <MediaQuery query="(max-width: 1200px)">
                        <div className={`toggle ${this.state.toggle == true ? "active" : ""}`} onClick={(e)=>{this.toggle(e)}}>
                            <div className="line line-1"></div>
                            <div className="line line-2"></div>
                        </div>
                    </MediaQuery>
                </div>
                <MediaQuery query="(max-width: 1200px)">
                    <OptionNav options={this.state} position={this.activePosition} moveTo={this.moveTo}></OptionNav>
                </MediaQuery>
            </nav>
        )
    }
}

class OptionNav extends Component{
    constructor(props){
        super(props)
        this.state = {
            expanded:""
        }
        console.log("movot",this.props)
    }
    handleChange = (panel) => (event, newExpanded) => {
        this.setState({expanded: newExpanded ? panel : false})
    };
    activeModal=()=>{
        try {
            document.querySelector(".modal").classList.toggle("active")
            
        } catch (error) {
            
        }
    }
    anchorLinkGoto = (anchorTarget) => {
        document.getElementById(anchorTarget).scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    render(){
        return(
        <div className={`container-link ${this.props.options.toggle ? "active" : ""}`}>
        <div className="links">
            <div className="center">
                <Link to="/" id="inicio" className={`link ${this.props.options.activeLine == 1 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,1)}}>
                    <div className="content-text cart">
                        <span className="text-link cart">INICIO</span>
                        <span className="barra-line"></span>
                    </div>
                </Link>
                <Link to="#galeria" id="inicio" className={`link ${this.props.options.activeLine == 2 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,2);
                        this.anchorLinkGoto("galeria");
                    }}>
                    <div className="content-text cart">
                        <span className="text-link cart">GALER&Iacute;A</span>
                        <span className="barra-line"></span>
                    </div>
                </Link>
                <Link id="ltipologias" to="#tipologias" className={`link ${this.props.options.activeLine == 3 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,3);
                        this.anchorLinkGoto("tipologias");
                    }}
                    >
                    <div className="content-text cart">
                        <span className="text-link cart">TIPOLOG&Iacute;AS</span>
                        <span className="barra-line"></span>
                    </div>
                </Link>
                <Link id="lubicacion" to="#ubicacion" className={`link ${this.props.options.activeLine == 4 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,4);
                        this.anchorLinkGoto("ubicacion");}}
                    >
                    <div className="content-text cart">
                        <span className="text-link cart">UBICACI&Oacute;N</span>
                        <span className="barra-line"></span>
                    </div>
                </Link>
                <div className='btn-cotizar-nav d-none-mobile'>
                    Cotizar
                </div>
            </div>
            <div className="link-buttons">
                <div className='btn-cotizar-nav d-none-desk'  onClick={(e)=>{this.props.moveTo("#nav","#formulario-cotizar");}}>
                    Cotizar
                </div>
                <a  target="_blank" href="https://www.facebook.com/IbherPeru" className={`link button`}>
                    <div className="redes mask facebook">
                    </div>
                </a>
                <a target="_blank" href="https://www.instagram.com/ibher_peru/?hl=es-la" className={`link button`}>
                    <div  className="redes mask instagram">
                    </div>
                </a>
                <a target="_blank" href="https://asei.com.pe/" className={`link link-brand`} >
                    <img  alt="Ibher Perú Miembro ASEI" className='' src={require('../../assets/images/icons/asei.png')} ></img>
                </a>
            </div>
        </div>
    </div>
    )
    }
}