import React, { Component } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import "./contacto.scss"
import { MailService } from '../common/services/MailService'
import { FormContainer, setInputProps } from '../common/forms/Form'
import { ContactValidatonSchema } from '../common/forms/constraints/ValidatonSchemas'
import { InterestedEmail } from '../common/MailTemplates/interestedEmail'
import { dorms } from './features'

export default class Contacto extends Component {
    constructor(props) {
        super(props)
        this.submitForm = this.submitForm.bind(this)
        this.mailService = new MailService()
        this.contactFormRef = React.createRef();
    }
    submitForm(values, {resetForm, setSubmitting}){
        const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima'}).split(",")[0]
        const [day, month, year]  = date.split("/")
        
        window.dataLayer && window.dataLayer.push({'event': "LeadCreated"})

        values.utm_source = "Orgánico"
        new URLSearchParams(localStorage.getItem("url_query")).forEach((v, k) => {
            /utm_/.test(k) && (values[`${k.toLowerCase()}`] = v)
        })

        axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbzUra7C0aOC6DHrWD58kiUr7Uhm3O2SpF0X7_pBrmg8Onqeu_gs5bS0qsoliWk9LpI/exec", {
            "ss_id": "1rRrAD14J6qYyo2tbk744p9QnvgsDc-64Cj1QQhIpvOA",
            "range": "Leads!A:XX",
            "values": [[
                date, day, month, year, null, values.fname, values.lname, values.fname + " " + values.lname, values.telephone, values.email, "Landing page",
                null, null, null, null, null, null, null, null, null, null, values.rooms_amount, null, "Contacto", values.contact_time,
                values.utm_source, values.utm_medium, values.utm_campaign, values.utm_content, values.utm_term
            ]]
        })

        this.mailService.from(`Fórmula <no-reply@formulaperu.com>`)
            .to("ngarayoa@ibherperu.com,fpalma@ibherperu.com")
            .subject("Tienes un nuevo Lead en Magnolia")
            .html(InterestedEmail({...values, lead_source: "<a href='https://magnolia-ibher.pe/'>magnolia-ibher.pe</a>"}))

        this.mailService.send().then(_=>{
            setSubmitting(false)
            resetForm()
            this.contactFormRef.current.reset()
            window.location.href = "/gracias-registro"
        })

    }
    initialValues = {
        fname: null,
        lname: null,
        email: null,
        telephone: null,
        rooms_amount: null,
        contact_time: null,
        terms: true
    }
    render() {
        return (
        <div className={"contacto "+this.props.page}>
            
            <h3 className="title">CONTÁCTANOS</h3>
            <FormContainer initialValues={this.initialValues} validationSchema={ContactValidatonSchema} onSubmit={this.submitForm}>
                {form => {const {handleSubmit, errors, touched, isSubmitting} = form;
                return(
                    <form onSubmit={handleSubmit} className="formulario" ref={this.contactFormRef}>
                        <div className="content-input col">
                            <span className="title-input">Nombre*</span>
                            <input type="text" {...setInputProps("fname", "input", form)}></input>
                        </div>
                        <div className="content-input col">
                            <span className="title-input">Apellido*</span>
                            <input type="text" {...setInputProps("lname", "input", form)}></input>
                        </div>
                        <div className="content-input large">
                            <span className="title-input">Email*</span>
                            <input type="email" {...setInputProps("email", "input", form)}></input>
                        </div>
                        <div className="content-input large">
                            <span className="title-input">Teléfono*</span>
                            <input type="tel" {...setInputProps("telephone", "input", form)}></input>
                        </div>
                        <div className="btn"><button type="submit" className="btn-submit btn-cotizar"disabled={isSubmitting}>{isSubmitting ? "Enviando..." : "Enviar"}</button></div>
                    </form>
                )}}
            </FormContainer>
        </div>
        )
    }
}
