const dataInicio = {
    title:require("../assets/images/template/title-departamentos-desde.png"),
    titleMovil:require("../assets/images/template/title-movil/title-inicio.png"),
    imgTemplate:require("../assets/images/template/img-template-inicio.webp"),
    paragraph:`
    <strong>Edificio de solo 7 pisos con 51 departamentos en Miraflores</strong>. Cuenta con materiales y acabados funcionales para conectarnos con tu estilo de vida, cada metro cuadrado ha sido aprovechado al máximo para brindarte un mayor confort.
    `,
    planos:require("../assets/files/Edificio-Magnolia-Brochure-Digital.pdf"),
    brochure:require("../assets/files/Magnolia-Ibher-Brochure-Digital.pdf"),
    tipologia:{
        title:"DEPARTAMENTO SS 01 B",
        techado:"135.30 m²",
        descubierto:"84.80 m²",
        img:require("../assets/images/template/tipologia-a.png"),
    },
    interiores:[
        {name:"Sala",img:require("../assets/images/template/interiores/all/sala1.webp")},
        {name:"Cocina",img:require("../assets/images/template/interiores/all/cocina1.webp")},
        {name:"Comedor",img:require("../assets/images/template/interiores/all/comedor1.webp")},
        {name:"Dormitorio",img:require("../assets/images/template/interiores/all/dormitorio-principal.webp")},
        {name:"Sala y comedor",img:require("../assets/images/template/interiores/all/sala-comedor.webp")},
        {name:"Baño",img:require("../assets/images/template/interiores/all/bath.webp")},
    ],

    tipologias:[
        {title:"105",techado:"110.00 m²",total:"110.00 m²",img:require("../assets/images/tipologias/105.webp"), numeroAmbientes: 3 },
        {title:"203",techado:"80.10 m²",total:"80.10 m²",img:require("../assets/images/tipologias/203.webp"), numeroAmbientes: 1 },
        {title:"204",techado:"80.60 m²",total:"80.60 m²",img:require("../assets/images/tipologias/204.webp"), numeroAmbientes: 2 },        
        {title:"601",techado:"110.20 m²",total:"110.20 m²",img:require("../assets/images/tipologias/601.webp"), numeroAmbientes: 3 },    
        {title:"604",techado:"110.10 m²",total:"110.10 m²",img:require("../assets/images/tipologias/604.webp"), numeroAmbientes: 3},
    ]

}
export default dataInicio;
