import React, { Component } from 'react'
import './footer.scss'
export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="container">
                    <p className="paragraph c-white">
                        Las imágenes y planos incluidos en este documento son recreaciones virtuales orientativas y no tienen carácter contractual, las viviendas se entragarán conforme a las
                        calidades detalladas en la memoria de calidades pudiéndo ser modiﬁcadas por la D.F. por razones técnicas, administrativas o de suministros, sin que esos cambios puedan 
                        signiﬁcar en nigún caso merma de calidad.
                    </p>
                    <div className='brands-footer'>
                        <a href='https://www.asei.com.pe/' target="_blank">
                            <img className='asei' src={require('../../assets/images/icons/asei.svg').default} alt="Ibher Perú ASEI"></img>
                        </a>
                        <a href='https://dci.pe/' target="_blank">
                            <img className='dci' src={require('../../assets/images/icons/dci.svg').default} alt="Ibher Perú Defensoría del Cliente Inmobiliario"></img>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
