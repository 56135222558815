import * as Yup from 'yup';

export const BaseInterestedConstraints = {
    fname: Yup.string()
        .required(),
    lname: Yup.string()
        .required(),
    email: Yup.string()
        .email()
        .required(),
    telephone: Yup.string()
        .matches(/[\+?\-?0-9]{7,}/)
        .required(),
}

export const ContactValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const RegisterValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints
    })